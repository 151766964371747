<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <mat-card>
        <mat-card-title align="center">
          <h2>Detalle de la Cuenta</h2>
        </mat-card-title>
        <hr />
        <mat-card-content align="justify">
          <h3>Número de la Cuenta: 10000029862592</h3>
          <h3>Banco: Banco Unión</h3>
          <h3>Nombre de la Cuenta: COMARCACM</h3>
          <h3>CI: 3113777 Or</h3>
          <h3>Nombre del originante: Carla Valencia Machado</h3>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-5" align="center">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="formAdquirirCurso" (ngSubmit)="submitAdquirirCurso($event)">
            <div *ngIf="porcentaje == 100" class="alert alert-success" role="alert">
              Archivo cargado: {{nombreArchivo}}
            </div>
            <mat-progress-bar *ngIf="porcentaje > 0 && porcentaje < 100" class="progress is-large is-success"
              value="{{ porcentaje }}" max="100">
              {{ porcentaje }}%
            </mat-progress-bar><br>
            <img mat-card-image [src]="imgSrc" class="imagenR" (click)="fileUploader.click()">
            <input type="file" formControlName="archivo" name="comprobante" #fileUploader hidden
              (change)="showPreview($event)" accept="image/jpeg,image/png">
            <mat-card-actions align="center">
              <div class="col-md-12">
                <!--<button mat-icon-button matTooltip="Carga tu comprobante" color="warn" (click)="fileUploader.click()">
                <mat-icon>add_photo_alternate</mat-icon>
              </button>-->
                <mat-checkbox class="example-margin" formControlName="check"><a mat-button
                    href="../../../../assets/archivos/Terminos y Condiciones de Uso plataforma Academia Co Marca 2022.pdf"
                    target="_blank">Acepto los términos y condiciones</a>
                </mat-checkbox>
                <button mat-raised-button matTooltip="Envía tu comprobante" [disabled]="!formAdquirirCurso.valid"
                  color="primary" class="col-md-12"> Envía tu
                  solicitud
                  <mat-icon>check_circle</mat-icon>
                </button>
              </div>
            </mat-card-actions>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-3">
      <div class="alert alert-warning" role="alert">
        Selecciona una imagen para subir tu comprobante. Una vez completada la carga envíalo. <br> Nota: Verifica que tu
        comprobante se haya subido con el
        nombre del archivo para enviar tu solicitud de compra.
      </div>
      <img class="avatar" src="./assets/img/avatars/avatar.png" alt="">
    </div>
  </div>
</div>
