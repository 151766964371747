<div class="container">
  <!--  Descripcion: Este es el contenido o Informacion del curso-->
  <div class="row py-3">
    <div *ngIf="!curso">
      <mat-spinner></mat-spinner>
    </div>
    <div class="col-md-4 boder py-4">
      <!--Card del curso:
                  * Fotografia
                  * Titulo
                  * Precio
                  * Secciones-->
      <mat-card class="example-card" *ngIf="curso">
        <!--Fotografia de referencia al curso-->
        <img mat-card-image src="{{ curso.imagen_curso }}" fallimg />
        <!--Informacion del curso-->
        <mat-card-header>
          <mat-card-title class="py-3"
            >{{ curso.nombre_curso }}
          </mat-card-title>
        </mat-card-header>
        <center>
          <h2><span>Bs.</span> {{ curso.precio }}</h2>
        </center>
        <!--<h3>Número de Secciones: {{ modulos.length }}</h3>
        <h3>Precio del Curso: Bs. {{ curso.precio }}</h3>-->
        <mat-card-content>
          <button
            mat-raised-button
            *ngIf="!estado; else logged"
            class="btn-block py-1"
            color="primary"
            routerLink="/register"
          >
            Registrate y compra el curso!
            <!--<a href="/register" style="color: white;">Registrate y compra el curso!</a>-->
          </button>

          <ng-template #logged>
            <button
              mat-raised-button
              *ngIf="curso.precio == 0"
              (click)="metodoFree()"
              class="btn-block py-1"
              color="accent"
            >
              Adquiere el Curso, GRATIS
            </button>
            
            <button mat-raised-button
                    *ngIf="!mostrarMetodo && !cursoComprado"
                    (click)="openCompra()"
                    class="btn-block py-1"
                    color="primary">
              ¡Compra el curso ahora!
            </button>

          </ng-template>

          <div *ngFor="let item of misCursos">

            <div *ngIf="curso.id_curso == item.id_curso; else sinComprar">

              <div
                class="alert alert-warning"
                role="alert"
                *ngIf="item.estado_usuario_curso == 'no confirmado'"
              >
                Aun no se aprobo la solicitud de tu compra
              </div>

              <div
                class="alert alert-success"
                role="alert"
                *ngIf="item.estado_usuario_curso == 'adquirido'"
              >
                Ya adquiriste este curso
              </div>

              <div
                class="alert alert-danger"
                role="alert"
                *ngIf="item.estado_usuario_curso == 'rechazado'"
              >
                Tu compra fue rechazada
              </div>

            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
      <mat-card class="example-card" [hidden]="!mostrarMetodo">
        <mat-card-header>
          <mat-card-title>Elige tu método de pago</mat-card-title>
        </mat-card-header>
        <div class="example-button-row">
          <div class="example-flex-container">
            <button
              mat-stroked-button
              class="btn-block"
              (click)="metodoDeposito()"
              matTooltip="Transferencia Bancaria"
              color="basic"
            >
              <mat-icon>receipt_long </mat-icon> Depósito Bancario
            </button>
            <button
              mat-stroked-button
              class="btn-block"
              (click)="metodoMone()"
              matTooltip="Pago por Mone"
              color="basic"
            >
              <mat-icon>qr_code</mat-icon> Pago por Mone
            </button>
            <button
              mat-stroked-button
              class="btn-block"
              (click)="metodoTarjeta()"
              matTooltip="Tarjeta de Credito/Debito"
              color="basic"
            >
              <mat-icon>credit_card</mat-icon> Tarjeta de Crédito/Débito
            </button>
            <button
              mat-stroked-button
              class="btn-block"
              (click)="metodoQr()"
              matTooltip="Pago por código QR"
              color="basic"
            >
              <mat-icon>qr_code_scanner</mat-icon> Pago mediante QR
            </button>
          </div>
        </div>
      </mat-card>
      <!--Final del Card-->
    </div>
    <div class="col-md-8 boder py-4">
      <!--Informacion del Curso-->
      <div class="container">
        <div *ngIf="curso">
          <mat-card>
            <mat-card-title class="title-curso"
              >{{ curso.nombre_curso }}
            </mat-card-title>
            <hr />
            <mat-card-content>
              <p align="justify">
                Número de Módulos:
                <a class="sub-seccion">{{ modulos.length }}</a>
              </p>
              <p>
                Precio del Curso:
                <a class="sub-seccion">{{ curso.precio }} Bs.</a>
              </p>
              <p>
                Fecha de Publicación:
                <a class="sub-seccion">{{
                  curso.created_at | date : "MM/dd/yy"
                }}</a>
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <br />
      <div class="container">
        <div *ngIf="curso">
          <mat-card>
            <mat-card-title class="py-2"
              >¿Qué aprenderás del curso?
            </mat-card-title>
            <hr />
            <p align="justify" class="descript">
              {{ curso.descripcion_curso }}
            </p>
            <!--Contenido del Curso-->
            <mat-card-title class="py-2"> Contenido del curso </mat-card-title>
            <hr />
            <mat-accordion *ngFor="let item of modulos; let i = index">
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p class="sub-seccion">Módulo {{ i + 1 }}: &nbsp;</p>
                    {{ item.nombre_modulo }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p class="descript">
                  {{ item.descripcion_modulo }}
                </p>
              </mat-expansion-panel>
              <br />
            </mat-accordion>
            <!--Final del contenido del curso-->
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <!--Final del Contenido o Informacion del curso-->
  <hr />
  <!--Presentacion del docente-->
  <div class="row">
    <div *ngIf="!docente">
      <mat-spinner></mat-spinner>
    </div>
    <!--Datos del docente-->
    <div class="col-md-7 py-4">
      <div *ngIf="docente">
        <mat-card>
          <div>
            <mat-card-title class="py-3 nom-doc"
              >{{ docente.nombre_usuario }}
            </mat-card-title>
          </div>
          <hr />
          <mat-card-content align="justify">
            <mat-card-subtitle
              >¿Quien es {{ docente.nombre_usuario }}?</mat-card-subtitle
            >
            <p class="descript">{{ datosDocente?.descripcion_docente }}</p>
            <mat-card-subtitle>Experiencia: </mat-card-subtitle>
            <p class="descript">{{ datosDocente?.experiencia_docente }}</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-md-5 py-4">
      <!--Video de presentacion, Fotografia y nombre del Usuario-->
      <mat-card class="doc-img" *ngIf="docente">
        <!--Fotografia de referencia-->
        <video
          mat-card-image
          src="{{ docente.datos_docente.video_presentacion }}"
          poster="{{ docente.foto_usuario }}"
          controls
          controlslist="nodownload"
        ></video>
        <mat-card-header>
          <mat-card-title class="py-3"
            >{{ docente.nombre_usuario }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </div>
  </div>
  <!--Final de la presentacion del Docente-->
  <!--Final del Contenido o Informacion del curso-->
</div>
