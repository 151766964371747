<div class="container">
  <div class="container-fluid body">
    <div class="py-5">
      <p class="lead">Mis solicitudes de compra y cursos adquiridos</p>
      <hr />
    </div>

    <div *ngIf="estado">
      <div class="alert alert-danger" role="alert">
        Aún no adquiriste ningún curso.
      </div>
    </div>
    <!--<div *ngIf="!estado" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>-->
    <div class="row">
      <div class="col-md-4" *ngFor="let miCurso of misCursos">
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>
              <a class="lead"
                >{{ miCurso.curso_solicitado.nombre_curso }}
                <hr />
              </a>
            </mat-card-title>
          </mat-card-header>
          <div
            *ngIf="
              miCurso.estado_usuario_curso == 'adquirido' ||
              miCurso.estado_usuario_curso == 'Concluido'
            "
          >
            <img
              mat-card-image
              src="{{ miCurso.curso_solicitado.imagen_curso }}"
              routerLink="/curso/curso-contenido/{{ miCurso.id_usuario_curso }}"
              class="imgCurso"
              fallimg
            />
          </div>
          <div *ngIf="miCurso.estado_usuario_curso == 'no confirmado'">
            <img
              mat-card-image
              src="{{ miCurso.curso_solicitado.imagen_curso }}"
              class="imgCurso"
              fallimg
            />
          </div>
          <div *ngIf="miCurso.estado_usuario_curso == 'rechazado'">
            <img
              mat-card-image
              src="{{ miCurso.curso_solicitado.imagen_curso }}"
              class="imgCurso"
              fallimg
            />
          </div>
          <mat-card-content>
            <hr />
            <div class="container row">
              <mat-card-subtitle>Estado de tu curso:</mat-card-subtitle>&nbsp;
              <mat-chip-list>
                <mat-chip
                  *ngIf="miCurso.estado_usuario_curso == 'no confirmado'"
                  color="accent"
                  selected
                >
                  {{ miCurso.estado_usuario_curso }}</mat-chip
                >
                <mat-chip
                  *ngIf="miCurso.estado_usuario_curso == 'rechazado'"
                  color="warn"
                  selected
                >
                  {{ miCurso.estado_usuario_curso }}</mat-chip
                >
                <mat-chip
                  *ngIf="miCurso.estado_usuario_curso == 'adquirido'"
                  routerLink="/curso/curso-contenido/{{
                    miCurso.id_usuario_curso
                  }}"
                  color="accent"
                  selected
                >
                  {{ miCurso.estado_usuario_curso }}</mat-chip
                >
                <mat-chip
                  *ngIf="miCurso.estado_usuario_curso == 'Concluido'"
                  color="primary"
                  selected
                >
                  {{ miCurso.estado_usuario_curso }}</mat-chip
                >
              </mat-chip-list>
            </div>
            <h3>Descripcion del Curso</h3>
            <div
              class="container row descripcion"
              [ngClass]="{ 'long-text': texto }"
              (click)="texto = !texto"
            >
              {{ miCurso.curso_solicitado.descripcion_curso }}
            </div>
          </mat-card-content>
          <mat-card-actions
            align="end"
            *ngIf="
              miCurso.estado_usuario_curso == 'adquirido' ||
              miCurso.estado_usuario_curso == 'Concluido'
            "
          >
            <button
              mat-stroked-button
              color="basic"
              routerLink="/curso/curso-contenido/{{ miCurso.id_usuario_curso }}"
            >
              IR A MI CURSO
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
