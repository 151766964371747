<div class="container-fluid body">
  <div class="row justify-content-center text-align-center title-content">
    <div class="col-md-12 py-5 text-white text-center">
      <p class="text-white text-align-center lead">Cursos pertenecientes a la categoría</p>
    </div>
  </div>
</div>
<hr>
<div class="container content">
  <div class="row justify-content-center" role="status">
    <div *ngIf="!categorias" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div *ngIf="estado">
      <div class="alert alert-danger col-12">
        <h4 class="mensaje">Aún no existen cursos pertenecientes de la categoría</h4>
      </div>
    </div>
    <div class="col-md-3" *ngFor="let miCurso of categorias">
      <mat-card class="example-card">
        <img mat-card-image src="{{ miCurso.imagen_curso }}" routerLink="/curso/curso-detalle/{{ miCurso.id_curso }}"
          class="img-cards" fallimg />
        <div>
          <hr>
        </div>
        <mat-card-header>
          <mat-card-title>
            <a class="lead" routerLink="/curso/curso-detalle/{{ miCurso.id_curso }}">{{ miCurso.nombre_curso }}</a>
          </mat-card-title>
          <br>
          <mat-card-subtitle>Precio: Bs. {{ miCurso.precio}}</mat-card-subtitle>
        </mat-card-header>
        <!--<mat-card-subtitle>Descripcion: </mat-card-subtitle>-->
        <mat-card-content>
          <div class="container" [ngClass]="{'long-text': texto}" (click)="texto = !texto">
            {{ miCurso.descripcion_curso }}
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
