<!--Spinner de carga del formulario-->
<div *ngIf="estadoCargando" class="text-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden"></span>
  </div>
</div>
<!--Spinner de carga del formulario-->
<!--Contenido del formulario-->
<div *ngIf="!estadoCargando">
  <form [formGroup]="formRegistrarRespuesta" (ngSubmit)="submitRegistrarRespuesta($event)" class="example-form">
    <mat-dialog-content class="mat-typography">
      <div class="container">
        <!--Comentario del estudiante o docente-->
        <mat-form-field class="example-full-width">
          <mat-label>Agrega un comentario publico</mat-label>
          <textarea matInput #input formControlName="texto_respuesta" type="text" maxlength="500"></textarea>
          <mat-hint align="end">{{input.value?.length || 0 }}/500</mat-hint>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <div mat-dialog-actions align="end">
      <button mat-button color="primary" [mat-dialog-close]>CANCELAR</button>
      <button mat-button color="warn" [disabled]="formRegistrarRespuesta.invalid">COMENTAR</button>
    </div>
  </form>
</div>
<!--Contenido del formulario-->
