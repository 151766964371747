import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../../services/usuario.service';
import { DocentesService } from '../../../../services/docentes.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FirebaseStorageService } from 'src/app/firebase-storage.service'; //CODIGO PARA FIREBASE STORAGE
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estudiante',
  templateUrl: './estudiante.component.html',
  styleUrls: ['./estudiante.component.scss'],
})
export class EstudianteComponent implements OnInit {
  respuesta: any;
  datosUsuario: any;
  formUsuario: FormGroup;
  formDocente: FormGroup;
  files: any = [];
  imagenPerfilCambio: any;
  estado = false;
  estadoVideo = false;
  estadoCv = false;
  rutaVideo: string;

  constructor(
    public srvEstudiante: UsuarioService,
    public srvDocente: DocentesService,
    private formBuilder: FormBuilder,
    private firebaseStorage: FirebaseStorageService, //CODIGO PARA FIREBASE STORAGE.
    public router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.comprobarAuth();
    this.buildForm();
    this.formBuild();
  }

  //CODIGO PARA FIREBASE STORAGE
  public archivoForm = new FormGroup({
    archivo: new FormControl('', Validators.required),
  });
  public mensajeArchivo = 'No hay un archivo seleccionado';
  public mensajeCv = 'No hay un archivo seleccionado';
  public datosFormulario = new FormData();
  public nombreArchivo = '';
  public nombreCv = '';
  public URLPublica = '';
  public URLVideo = '';
  public URLCv = '';
  public porcentaje = 0;
  public porcentajeVideo = 0;
  public porcentajeCv = 0;
  public finalizado = false;
  public finalizadovideo = false;
  public finalizadoCv = false;


  public datosFormV = new FormData();

  comprobarAuth(): void {
    if (localStorage.getItem('datosUsuario') !== null) {
      this.datosUsuario = localStorage.getItem('datosUsuario');
      this.datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
      console.log(this.datosUsuario);
      this.estado = true;
    }
  }
  datosUsu(): void {
    const datos = JSON.parse(localStorage.getItem('datosUsuario'));
    const id = datos.id_usuario;
    this.srvEstudiante.obtenerUsuario(id).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
  }
  buildForm(): void {
    this.formUsuario = this.formBuilder.group({
      nombre_usuario: ['', Validators.required],
      correo_usuario: ['', Validators.required],
    });
    this.setForm();
  }
  formBuild(): void {
    this.formDocente = this.formBuilder.group({
      telefono_docente: ['', Validators.required],
      descripcion_docente: ['', Validators.required],
      experiencia_docente: ['', Validators.required]
    });
    this.setData();
  }
  setForm(): void {
    this.formUsuario.get('nombre_usuario').setValue(this.datosUsuario.nombre_usuario);
    this.formUsuario.get('correo_usuario').setValue(this.datosUsuario.correo_usuario);
  }
  setData(): void {
    this.formDocente.get('telefono_docente').setValue(this.datosUsuario.datos_docente?.telefono_docente);
    this.formDocente.get('descripcion_docente').setValue(this.datosUsuario.datos_docente?.descripcion_docente);
    this.formDocente.get('experiencia_docente').setValue(this.datosUsuario.datos_docente?.experiencia_docente);
  }
  submitEditarUsuario(event: Event): void {
    event.preventDefault();
    console.log(this.formUsuario.value);
    const myFormData = new FormData();
    const datos = JSON.parse(localStorage.getItem('datosUsuario'));
    const id = datos.id_usuario;
    myFormData.append('id_usuario', id);
    myFormData.append('_method', 'put');
    myFormData.append('nombre_usuario', this.formUsuario.get('nombre_usuario').value);
    myFormData.append('correo_usuario', this.formUsuario.get('correo_usuario').value);
    Swal.fire({
      title: 'Datos de Perfil',
      text: '¿Seguro que desea actualizar los cambios realizados?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No, cancelar!',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.srvEstudiante.actualizarUsuario(myFormData, id).subscribe(res => {
          const obj: any = res;
          localStorage.setItem('datosUsuario', JSON.stringify(obj.datosUsuario));
          this.datosUsuario;
          console.log(res);
          this.respuesta = res;
          Swal.fire({
            text: this.respuesta.mensaje,
            icon: this.respuesta.estado
          });
        }, error => {
          console.log(error);
        });
      }
    });
  }

  
//FOTO DE PERFIL
uploadFile(event): void {
  if (event.target.files && event.target.files.length > 0) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      this.mensajeArchivo = `Archivo preparado: ${file.name}`;
      this.nombreArchivo = file.name;
      this.datosFormulario.delete('archivo');
      this.datosFormulario.append('archivo', file, file.name);

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagenPerfilCambio = e.target.result;
      };
      reader.readAsDataURL(file);
    }
    console.log('nombreArchivo: ', this.nombreArchivo);
  }
  console.log('nombreArchivo: ', this.nombreArchivo);
}

// Sube el archivo a Cloud Storage
public cambiarFoto() {
  let archivo = this.datosFormulario.get('archivo');
  let tarea = this.firebaseStorage.tareaCloudStorage(this.nombreArchivo, archivo);

  // Cambia el porcentaje
  tarea.percentageChanges().subscribe((porcentaje) => {
    this.porcentaje = Math.round(porcentaje);
    console.log(this.porcentaje);
    if (this.porcentaje === 100) {
      this.finalizado = true;
      let referencia = this.firebaseStorage.referenciaCloudStorage(this.nombreArchivo);
      referencia.getDownloadURL().subscribe((URL) => {
        this.URLPublica = URL;
        console.log(this.URLPublica);
        this.uploadPhoto();
      });
    }
  });
}

uploadPhoto() {
  console.log('filedata: ', this.URLPublica);
  const myFormData = new FormData();
  const datos = JSON.parse(localStorage.getItem('datosUsuario'));
  const id = datos.id_usuario;
  myFormData.append('id_usuario', id);
  myFormData.append('_method', 'put');
  myFormData.append('foto_usuario', this.URLPublica);

  this.srvEstudiante.actualizarFoto(myFormData, id).subscribe(res => {
    console.log(res);
    this.respuesta = res;
    Swal.fire({
      text: this.respuesta.mensaje,
      icon: this.respuesta.estado
    });
    this.datosUsu();
    this.imagenPerfilCambio = false;
    this.datosUsuario.foto_usuario = this.URLPublica;
  }, error => {
    console.log(error);
  });
}

deleteAttachment(index): void {
  this.files.splice(index, 1);
  this.imagenPerfilCambio = false;
}
//FOTO DE PERFIL


  actualizarPerfilDocente(event: Event): void {
    event.preventDefault();
    console.log(this.formDocente.value);
    Swal.fire({
      title: 'Datos de Perfil',
      text: 'seguro que desea actualizar los cambios realizados!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No, cancelar!',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.srvDocente.actualizarDocente(this.formDocente.value, this.datosUsuario.id_usuario).subscribe(res => {
          const obj: any = res;
          localStorage.setItem('datosUsuario', JSON.stringify(obj.datosUsuario));
          console.log(res);
          window.location.reload();
          this.respuesta = res;
          Swal.fire({
            text: this.respuesta.mensaje,
            icon: this.respuesta.estado
          });
        }, error => {
          console.log(error);
        });
      }
    });
  }

  //CAMBIO DE VIDEO
  uploadVideo(event): void {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
        this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        const reader = new FileReader();
        reader.onload = (e: any) => this.estadoVideo = e.target.result;
        reader.readAsDataURL(event.target.files[0]);
      }
      console.log('nombreArchivo: ', this.nombreArchivo);
    }
    console.log('nombreArchivo: ', this.nombreArchivo);
  }

  //Sube el archivo a Cloud Storage
  public cambiarVideo() {
    let archivo = this.datosFormulario.get('archivo');
    let tarea = this.firebaseStorage.tareaCloudStorage(this.nombreArchivo, archivo);
    //Cambia el porcentaje
    tarea.percentageChanges().subscribe((porcentaje) => {
      this.porcentajeVideo = Math.round(porcentaje);
      console.log(this.porcentajeVideo);
      if (this.porcentajeVideo == 100) {
        //this.estadoCargando = true;
        this.finalizadovideo = true;
        let referencia = this.firebaseStorage.referenciaCloudStorage(this.nombreArchivo);
        referencia.getDownloadURL().subscribe((URL) => {
          this.URLVideo = URL;
          console.log(this.URLVideo);
          this.saveVideo();
        });
      }
    });
  }

  saveVideo() {
    console.log('filedata: ', this.URLVideo);
    const myFormData = new FormData();
    myFormData.append('id_docente', this.datosUsuario.datos_docente.id_docente + '');
    myFormData.append('_method', 'put');
    myFormData.append('video_presentacion', this.URLVideo);
    this.srvDocente.actualizarVideo(myFormData).subscribe(res => {
      console.log(res);
      this.respuesta = res;
      Swal.fire({
        text: this.respuesta.mensaje,
        icon: this.respuesta.estado
      });
      this.estadoVideo = false;
      //window.location.reload();
    }, error => {
      console.log(error);
    });
  }

  deleteAttachmentVideo(index): void {
    this.files.splice(index, 1);
    this.estadoVideo = false;
  }
  //CAMBIO DE VIDEO

  //CAMBIO DE CV
  uploadCv(event): void {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeCv = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreCv = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
        this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        const reader = new FileReader();
        reader.onload = (e: any) => this.estadoCv = e.target.result;
        reader.readAsDataURL(event.target.files[0]);
      }
      console.log('nombreArchivo: ', this.nombreCv);
    }
    console.log('nombreArchivo: ', this.nombreCv);
  }

  //Sube el archivo a Cloud Storage
  public cambiarCv() {
    let archivo = this.datosFormulario.get('archivo');
    let tarea = this.firebaseStorage.tareaCloudStorage(this.nombreCv, archivo);
    //Cambia el porcentaje
    tarea.percentageChanges().subscribe((porcentaje) => {
      this.porcentajeCv = Math.round(porcentaje);
      console.log(this.porcentajeCv);
      if (this.porcentajeCv == 100) {
        //this.estadoCargando = true;
        this.finalizadoCv = true;
        let referencia = this.firebaseStorage.referenciaCloudStorage(this.nombreCv);
        referencia.getDownloadURL().subscribe((URL) => {
          this.URLCv = URL;
          console.log(this.URLCv);
          this.saveCv();
        });
      }
    });
  }

  saveCv() {
    console.log('filedata: ', this.URLCv);
    const myFormData = new FormData();
    myFormData.append('id_docente', this.datosUsuario.datos_docente.id_docente + '');
    myFormData.append('_method', 'put');
    myFormData.append('cv_docente', this.URLCv);
    this.srvDocente.actualizarCv(myFormData).subscribe(res => {
      console.log(res);
      this.respuesta = res;
      Swal.fire({
        text: this.respuesta.mensaje,
        icon: this.respuesta.estado
      });
      this.estadoCv = false;
      //window.location.reload();
    }, error => {
      console.log(error);
    });
  }

  deleteAttachmentCv(index): void {
    this.files.splice(index, 1);
    this.estadoCv = false;
  }
  //CAMBIO DE CV
}



