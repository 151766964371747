<h1 mat-dialog-title>Actualiza tus datos personales{{datosUsuario.id_usuario}}</h1>
<form [formGroup]="formUsuario" (ngSubmit)="submitEditarUsuario($event)">
  <div mat-dialog-content>
    <div class="row">
      <mat-form-field class="col-md-12">
        <mat-label>Ingresa tu nombre completo: </mat-label>
        <input matInput formControlName="nombre_usuario" type="text" placeholder="">
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <mat-label>Ingresa tu correo electrónico:</mat-label>
        <input matInput formControlName="correo_usuario" type="email" placeholder="">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <mat-label>Ingresa tu contraseña:</mat-label>
        <input matInput formControlName="password_usuario" type="text" placeholder="">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <mat-label>Ingresa nuevamente tu contraseña:</mat-label>
        <input matInput formControlName="password_usuario_repeat" type="text" placeholder="">
      </mat-form-field>
      <button type="submit" class="col-md-12" mat-raised-button [disabled]="formUsuario.invalid">Guardar Cambios</button>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="warn" type="button" (click)="onNoClick()">cancelar</button>
  </div>
</form>
