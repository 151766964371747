import { Component, OnInit, Input, Inject } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pago-tarjeta',
  templateUrl: './pago-tarjeta.component.html',
  styleUrls: ['./pago-tarjeta.component.scss']
})
export class PagoTarjetaComponent implements OnInit {
  formPagoTarjeta: FormGroup;
  name = 'Dynamic Form Action Demo';
  link: any;
  respuesta: any;
  @Input()
  id: any;
  enlace: any;
  idD: any;
  idd: any;
  showButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<PagoTarjetaComponent>,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.buildForm();
    this.id = data[0];
    this.idD = data[1];
    this.idd = data[2];
    this.showButton = false;
  }

  // tslint:disable-next-line: typedef
  ngOnInit(): void {
    this.buildForm();
    // console.log(this.id);
    // console.log(this.idD);
    // console.log(this.idd);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  linkser(event: Event) {
    event.preventDefault();
    this.showButton = true;
    const myFormData = new FormData();
    const datos = JSON.parse(localStorage.getItem('datosUsuario'));
    const id = datos.id_usuario;
    myFormData.append('id_usuario', id);
    myFormData.append('id_curso', this.id);
    // console.log(this.id + ' ' + id);
    this.usuarioService
      .linkSer(this.formPagoTarjeta.value)
      .subscribe((newLinkser) => {
        Swal.fire({
          icon: 'warning',
          title: 'Verifique',
          text: 'En el apartado de "Recibo" podrá verificar los datos antes de realizar la compra',
          confirmButtonText: 'Aceptar',
        });
        this.respuesta = newLinkser;
        // console.log(this.respuesta);
        if (this.respuesta) {
          this.enlace = this.respuesta.data;
        }
      });
  }

  buildForm(): void {
    // const myFormData = new FormData();
    const datos = JSON.parse(localStorage.getItem('datosUsuario'));
    const id = datos.id_usuario;
    // myFormData.append('id_usuario', id);
    // myFormData.append('id_curso', this.id);
    // console.log(this.id + ' ' + id);

    this.formPagoTarjeta = this.formBuilder.group({
      id_curso: [this.idD],
      id_usuario: [id],
      amount: [this.id, [Validators.required]],
      currency: ['BOB', [Validators.required]],
      bill_to_forename: ['', [Validators.required]],
      bill_to_surname: ['', [Validators.required]],
      bill_to_email: ['', [Validators.required]],
      bill_to_phone: ['', [Validators.required]],
      bill_to_address_line1: ['', [Validators.required]],
      bill_to_address_state: ['', [Validators.required]],
      bill_to_address_country: ['', [Validators.required]],
      card_type: ['', [Validators.required]],
      card_number: ['', [Validators.required]],
      card_expiry_datemes: ['', [Validators.required]],
      card_expiry_dateyear: ['', [Validators.required]],
      card_cvn: ['', [Validators.required]],
    });
  }
}

 