<mat-card>
  <mat-card-header>
    <mat-card-title>NOMBRE DEL CURSO<hr></mat-card-title>
  </mat-card-header>
  <img mat-card-image src="/../../../../assets/img/fondo.jpg"class="imgCurso">
  <mat-card-subtitle>Mensaje del revisor:
  </mat-card-subtitle>
  <mat-card-content>
  <h3>Etiquetas Curso</h3>
    <div class="container">
      <mat-chip-list aria-label="eltiquetas" class="row">
        <mat-chip class="col-md">etiquetas 1</mat-chip>
        <mat-chip class="col-md">etiquetas 2</mat-chip>
        <mat-chip class="col-md">etiquetas 3</mat-chip>
        <mat-chip class="col-md">etiquetas 4</mat-chip>
      </mat-chip-list>
    </div>
    <br>
  </mat-card-content>
  <mat-card-footer>
    <mat-card-actions align="center">
      <button mat-raised-button color="accent">Cambiar Etiquetas</button>
      <button mat-raised-button color="primary">Administrar Curso</button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>
