import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pago-deposito',
  templateUrl: './pago-deposito.component.html',
  styleUrls: ['./pago-deposito.component.scss']
})
export class PagoDepositoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
