<div mat-dialog-content>
  <div class="row col-12" *ngIf="this.estadoP == 'warning'">
    <h3 class="text-center title-examen">
      {{ this.mensaje }}
    </h3>
    <div class="row col-12">
      <button
        mat-raised-button
        color="primary"
        class="col-md-12"
        mat-dialog-close="true"
      >
        De acuerdo!
      </button>
    </div>
  </div>
  <div class="container" *ngIf="this.estadoP == 'success'">
    <div class="card row">
      <h3 class="card-header text-center title-examen">
        Evaluación general del curso
      </h3>
      <div class="card-body col-md-12 justify-content-center align-self-center">
        <mat-accordion
          class="example-headers-align col-md-12"
          *ngFor="let item of prueba; let i = index"
        >
          <form [formGroup]="formRegistrar" autocomplete="off">
            <mat-expansion-panel
              [expanded]="step === i + 1"
              (opened)="setStep(i + 1)"
              hideToggle
              [disabled]="true"
              [state]="state"
            >
              <mat-expansion-panel-header class="title_prueba">
                <h3>💬 Prueba {{ i + 1 }}</h3>
              </mat-expansion-panel-header>
              <mat-panel-title class="title_prueba">
                <p>{{ item.texto_prueba }}</p>
              </mat-panel-title>
              <mat-radio-group
                aria-label="Select an option"
                formControlName="texto_prueba_opcion"
              >
                <div *ngFor="let opcion of item.prueba_opcion" align="justify">
                  <mat-radio-button (click)="getId(opcion.id_prueba_opcion)">
                    <div mat-line>
                      {{ opcion.texto_prueba_opcion }}
                    </div>
                  </mat-radio-button>
                </div>
              </mat-radio-group>
              <mat-action-row>
                <div class="d-flex justify-content-between">
                  <button mat-button color="basic" (click)="submitRegistrar()">
                    Siguiente
                  </button>
                </div>
              </mat-action-row>
            </mat-expansion-panel>
          </form>
        </mat-accordion>
        <div class="row">
          <button
            mat-raised-button
            color="primary"
            class="col-md-12"
            mat-dialog-close="true"
            (click)="closeEv()"
          >
            Verificar Evaluación!
          </button>
          <button
            mat-raised-button
            color="red"
            class="col-md-12"
            mat-dialog-close="true"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
