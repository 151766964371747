export const MENU_DOCENTE = [
  // {
  //    grupo: 'Inicio',
  //    contenido: [
  //      {
  //       titulo: 'Mis cursos adquiridos',
  //       link: '/estudiante/mis-cursos-adquiridos'
  //     }
  //    ]
  //  },
  {
    grupo: 'Cursos',
    contenido: [
      {
        titulo: 'Mi panel de cursos creados',
        link: './curso/mis-cursos',
      }, 
      {
        titulo: 'Tutoriales',
        link: 'cursos/tutoriales'
      },
      /*{
        titulo: 'Mis Cursos',
        link: 'cursos/estudiantes'
      }*/
    ]
  },
  // {
  //   grupo: 'Membresias', // Parecido
  //   contenido: [
  //     {
  //       titulo: 'Adquirir Membresia',
  //       link: './membresias/docente-membresia'
  //     }
  //   ]
  // }
];

export const MENU_ADMIN = [
  {
    grupo: 'Reportes y Graficas',
    contenido: [
      {
        titulo: 'Dashboard',
        link: './reportes'
      },
      {
        titulo: 'Cursos',
        link: './reportes/cursos-info'
      },
      {
        titulo: 'Instructores',
        link: './reportes/instructores-info'
      },
      {
        titulo: 'Usuarios',
        link: './reportes/usuarios-info'
      },
    ]
  },
  {
    grupo: 'Cursos',
    contenido: [
      {
        titulo: 'Administrar Cursos',
        link: './cursos/administar-cursos',
      },
      {
        titulo: 'Solicitudes de Cursos',
        link: './cursos/lista-cursos-revision',
      },
      {
        titulo: 'Solicitudes de Compra',
        link: './cursos/solicitudes-cursos-compra',
      },
    ]
  },
  {
    grupo: 'Membresias', // Parecido
    contenido: [
      {
        titulo: 'Administrar Membresia',
        link: './membresias'
      },
      {
        titulo: 'Solicitud de Membresia',
        link: './membresias/solicitudes',
      }
    ]
  },
  {
    grupo: 'Preguntas y encuestas',
    contenido: [
      {
        titulo: 'Preguntas Frecuentes',
        link: './preguntas-frecuentes'
      },
      {
        titulo: 'Encuestas',
        link: './encuestas'
      }
    ]
  }, {
    grupo: 'Docentes',
    contenido: [
      {
        titulo: 'Solicitud Docente',
        link: './usuarios/administrar',
      }
    ]
  },
  {
    grupo: 'Categorias',
    contenido: [
      {
        titulo: 'Administrar Categorias',
        link: './etiquetas'
      }
    ]
  }
];
