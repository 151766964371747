<router-outlet>
</router-outlet>
<div>
  <div class="fb-customerchat" page_id="705715713602577"></div>
</div>

<!-- Messenger plugin de chat Code -->
<div id="fb-root"></div>

<!-- Your plugin de chat code -->
<div id="fb-customer-chat" class="fb-customerchat">
</div>
