<div class="contenido mb-5">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a routerLink="" class="navbar-brand">
      <!-- Academia CoMarca -->
      <img src="../../../assets/img/Logo_2023_fondo.png" height="50px" alt="" />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- MENU PARA ADMINISTRADORES -->
      <ul class="navbar-nav mr-auto" style="height: 100%">
        <li
          *ngFor="
            let item of datosUsuario.id_rol == 1
              ? menuAdmin
              : datosUsuario.id_rol == 2
              ? menuDocente
              : otroMenu
          "
          class="nav-item dropdown align-middle"
        >
          <a
            class="btn btn-block btn-hover dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ item.grupo }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              *ngFor="let items of item.contenido"
              class="dropdown-item btn btn-sm navbar-hover"
              [routerLink]="items.link"
              >{{ items.titulo }}</a
            >
          </div>
        </li>
        <div class="dropdown-divider"></div>
      </ul>

      <div class="my-2 my-lg-0">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item dropdown">
            <a
              class="btn btn-block btn-hover dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="text-uppercase">
                <img
                  class="avatar"
                  [src]="datosUsuario.foto_usuario"
                  (error)="imagenError()"
                />
                {{ datosUsuario.nombre_usuario }}
              </span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <a
                class="dropdown-item btn btn-block navbar-hover"
                routerLink="/estudiante/mi-perfil"
                >Mi Perfil</a
              >
              <a
                class="dropdown-item btn btn-block navbar-hover"
                routerLink="/admin/curso/mis-cursos"
                >Mis Cursos Creados</a
              >
              <a
                class="dropdown-item btn btn-block navbar-hover"
                routerLink="/estudiante/mis-cursos-adquiridos"
              >
                Mis Cursos Adquiridos
              </a>
              <a
                *ngIf="datosUsuario.id_rol == 1"
                class="dropdown-item btn btn-block navbar-hover"
                routerLink="admin/reportes"
              >
                Panel Administrativo
              </a>

              <a
                class="dropdown-item btn btn-block navbar-hover"
                routerLink="./membresias/docente-membresia"
              >
                Membresias
              </a>

              <div class="dropdown-divider"></div>
              <a class="btn btn-danger btn-block" (click)="cerrarSesion()">
                Cerrar Sesión
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- CONTENIDO HEREDADO -->
  <div>
    <router-outlet></router-outlet>
  </div>
  <!-- FIN CONTENIDO HEREDADO -->
</div>

<!-- <section id="footer"> -->
<!-- <img src="../../../../assets/img/wave2.png" class="footer-img" alt="" />
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h4>De emprendedores para emprendedores.</h4>
        <P>Calacoto, Calle 12 y Av. Fuerza Naval</P>
        <p>Pasaje Inofuentes #502.</p>
      </div>
      <div class="col-md-4">
        <div class="icons">
          <a
            href="https://www.facebook.com/Academia-Co-Marca-705715713602577/"
            target="_blank"
            ><i class="fa fa-facebook"></i
          ></a>
          <a
            href="https://www.youtube.com/channel/UCWz0S-q6Uzu_sUS7luevttw/featured"
            target="_blank"
            ><i class="fa fa-youtube"></i
          ></a>
          <a
            href="https://www.instagram.com/academia_co_marca/?hl=es"
            target="_blank"
            ><i class="fa fa-instagram"></i
          ></a>
        </div>
        <div>
          <p>©2023, By Academia Co Marca</p>
        </div>
        <div>
          <a href="">
            <p>Polí­tica de Privacidad</p>
          </a>
          <a href="">
            <p>Condiciones</p>
          </a>
        </div>
      </div>
    </div>
  </div> -->

<!-- <footer class="footer">
    <div class="waves">
      <div class="wave" id="wave1"></div>
      <div class="wave" id="wave2"></div>
      <div class="wave" id="wave3"></div>
      <div class="wave" id="wave4"></div>
    </div>
    <ul class="social-icon">
      <li class="social-icon__item">
        <a
          href="https://www.facebook.com/Academia-Co-Marca-705715713602577/"
          target="_blank"
          ><i class="fa fa-facebook" aria-hidden="true"> Facebook </i></a
        >
      </li>
      <li class="social-icon__item">
        <a
          href="https://www.instagram.com/academia_co_marca/?hl=es"
          target="_blank"
          ><i class="fa fa-instagram" aria-hidden="true"> Instagram </i></a
        >
      </li>
      <li class="social-icon__item">
        <a
          href="https://www.youtube.com/channel/UCWz0S-q6Uzu_sUS7luevttw/featured"
          target="_blank"
          ><i class="fa fa-youtube" aria-hidden="true"> Youtube </i></a
        >
      </li>
    </ul>
    <p>
      De emprendedores para emprendedores. Calacoto, Calle 12 y Av. Fuerza Naval
      Pasaje Inofuentes #502.
    </p>
    <p>&copy;2023 CoMarca | Todos los derechos reservados</p>
  </footer> -->
<!-- </section> -->
