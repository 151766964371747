<div class="container-fluid border py-2">
  <div class="container py-3">
    <div class="row">
      <div class="col-md-7">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/US%2F3.jpeg?alt=media&token=59ef6a68-4318-43f9-a45d-3412054fbe71"
          alt="imagen2" class="img-fluid">
      </div>
      <div class="col-md-5 align-self-center">
        <div class="card">
          <div class="card-body text-center text-secondary">
            <h2 class="card-title">¿Quiénes somos?</h2>
            <h1>
              <p class="lead" align="justify">
                Somos una plataforma de aprendizaje en línea, creada y desarrollada por bolivianos, enfocada a la
                capacitación constante e ininterrumpida, actualizada y flexible al ritmo de cada uno con posibilidad de
                cruzar fronteras enseñando y aprendiendo
              </p>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container py-4">
    <div class="row justify-content-center">
      <div class="text-center">
        <h1 class="section-title">
          ¿A quiénes nos dirigimos?
        </h1>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-card>
          <img mat-card-image
            src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-7.jpeg?alt=media&token=3e9871b1-d6d9-4811-a480-297c91c96cd6">
          <mat-card-content>
            <h2 class="card-title">Personas amantes del aprendizaje continuo</h2>
            <h3>
              <p class="card-text lead" align="justify">Personas como tú que dedican mucho tiempo de su día a explorar
                el mundo, aprender, profundizar y ampliar habilidades, así como buscar nuevos desafíos a través del
                desarrollo integral continuo.</p>
            </h3>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-md-6">
        <mat-card>
          <img mat-card-image
            src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-1.jpeg?alt=media&token=967a7c52-9ee6-4433-b832-7388100e2cec">
          <mat-card-content>
            <h2 class="card-title">Apasionados por enseñar</h2>
            <h3>
              <p class="card-text lead" align="justify">Especialistas en su área que les apasiona compartir
                conocimiento, dispuestos a ingresar a un formato digital, disruptivo y con posibilidades de expandir sus
                saberes al mundo, así como crear una nueva cultura y comunidad.</p>
            </h3>
          </mat-card-content>
        </mat-card>
      </div>
    </div>


  </div>
</div>

<div class="container-fluid body py-5">
  <br>
  <div class="row justify-content-center">
    <div class="text-center">
      <h1 class="section-title">
        Ventajas de la Academia CO MARCA
      </h1>
      <br>
    </div>
  </div>
  <div class="container ">
    <div class="row">
      <!--VENTAJAS ACA-->
      <div class=" col-md-4">
        <div class="card-ventajas">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/US%2F5.jpeg?alt=media&token=011ea588-b184-4399-bc7d-7a51f35f7012"
            class="img-fluid" alt=" ">
          <div class="card-body">
            <h2 class="card-title">Contenido Especializado</h2>
            <h2>
              <p class="card-text lead " align="center">En La Academia encontrarás contenido especializado y
                complementario enfocado a distintas áreas estratégicas que permitan potenciarte con ventajas
                competitivas en tu dinamismo.</p>
            </h2>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card-ventajas">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/US%2F6.jpeg?alt=media&token=a4f415c7-f39e-4080-bb24-140fd7527ae2"
            class="img-fluid" alt=" ">
          <div class="card-body">
            <h2 class="card-title">Enfocada al Emprendimiento</h2>
            <h2>
              <p class="card-text lead" align="center">Somos parte activa del ecosistema
                emprendedor boliviano, esa experiencia nos motiva a reflejarla en La Academia, especializando cada
                disciplina al
                desarrollo del sector.</p>
            </h2>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card-ventajas">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/US%2F4.jpeg?alt=media&token=e74241f4-1844-4969-bd52-a8151ae26257"
            class="img-fluid" alt=" ">
          <div class="card-body">
            <h2 class="card-title">Ser parte de La Academia</h2>
            <h2>
              <p class="card-text lead" align="center">Ser integrante te da oportunidad de ingresar a una comunidad e
                interactuar en ella. Compartir tus conocimientos y crear empleabilidad orgánica.
              </p>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--CONTACTS-->
<!--<div class="container-fluid info text-white py-5">
  <form>
    <div class="row">
      <div class="col-md-4">
        <h3 class="lead text-center">¿Necesitas más información?
          <br> Comunícate con nosotros.
        </h3>
      </div>
      <div class="col-md-4">
        <input type="email" id="email" class="form-control form-control-lg" placeholder="Ingresa tu correo electrónico">
      </div>
      <div class="col-md-4">
        <button onclick="myFunction()" class="btn btn-info btn-lg btn-block">Enviar</button>
      </div>
    </div>
  </form>
</div>-->
<!--END CONTACTS-->

<!--END FORMULARIO-->
<div *ngIf="datosUsuario">
  <div class="container py-3" *ngFor="let item of encuesta">
    <div class="card" *ngFor="let rol of item.roles">
      <div *ngIf="datosUsuario.id_rol == rol.id_rol">
        <h3 class="card-header text-center">Realiza esta pequeña encuesta: {{item.texto_encuesta}}</h3>
        <div class="card-body justify-content-center align-self-center">
          <!--Formulario de registro-->
          <form [formGroup]="formRegistrarRespuesta" (ngSubmit)="submitRegistrar($event)" autocomplete="off">
            <div class="row justify-content-center text-align-justify"
              *ngFor="let preguntas of item.encuesta_pregunta; let i = index">
              <legend class="col-form-label col-7">
                {{ i + 1 }}. {{preguntas.texto_encuesta_pregunta}}</legend>
              <div class="col-5">
                <mat-radio-group aria-label="Select an option" formControlName="texto_encuesta_respuesta">
                  <mat-radio-button type="radio" name="texto_encuesta_respuesta" value="si"
                    (click)="getIdP(preguntas.id_encuesta_pregunta)">
                    Si</mat-radio-button>
                  <mat-radio-button type="radio" name="texto_encuesta_respuesta" value="no"
                    (click)="getIdP(preguntas.id_encuesta_pregunta)">
                    No</mat-radio-button>
                </mat-radio-group>
                <div class="row justify-content-end" align="end">
                  <button mat-icon-button color="primary" type="submit" [disabled]="formRegistrarRespuesta.invalid">
                    <mat-icon>send</mat-icon>
                  </button>
                </div>
              </div>

            </div>

          </form>
        </div>
      </div>

      <!--<mat-accordion class="example-headers-align" *ngFor="let preguntas of item.encuesta_pregunta; let i = index">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ i + 1 }}. {{preguntas.texto_encuesta_pregunta}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid">
          <form [formGroup]="formRegistrarRespuesta" (ngSubmit)="submitRegistrar($event)" autocomplete="off">
            <div class="row justify-content-center text-align-justify">-->
      <!-- ID que recibimos de firebase-->
      <!--<div class="col-5">-->
      <!--<div class="form-group">
                    <input id="si" type="radio" value="si" name="texto_encuesta_respuesta"
                      formControlName="texto_encuesta_respuesta">
                    <input id="no" type="radio" value="no" name="texto_encuesta_respuesta"
                      formControlName="texto_encuesta_respuesta">
                  </div>-->
      <!-- <mat-radio-group aria-label="Select an option" formControlName="texto_encuesta_respuesta">
                <mat-radio-button type="radio" name="texto_encuesta_respuesta" value="si"
                  (click)="getIdP(preguntas.id_encuesta_pregunta)">
                  Si</mat-radio-button>
                <mat-radio-button type="radio" name="texto_encuesta_respuesta" value="no"
                  (click)="getIdP(preguntas.id_encuesta_pregunta)">
                  No</mat-radio-button>
              </mat-radio-group>
            </div>-->
      <!--</div>-->
      <!-- <button type="submit" mat-raised-button class="btn btn-info col-12">Envia tu respuesta!</button>
          </form>
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>-->
    </div>
  </div>
</div>
<!--END FORMULARIO-->
