<section class="banner" id="banner">
  <div class="content">
    <h1>¡HAZ UN IMPACTO EN TI!</h1>
    <p>La Academia Co Marca expone tus cursos de manera integral. Ahora es posible crear un curso en línea, escalar y
      monetizar enseñando a una cantidad ilimitada de alumnos en todo el mundo.
    </p>
    <p>¡ESCALA CON LO QUE TE APASIONA!</p>
    <!--<button class="btn btn-light px-5 py-2" *ngIf="!estado; else logged" routerLink="/register">Convierte en
      instructor</button>-->
    <a class="btn" *ngIf="!estado; else logged" routerLink="/register">Conviértete en
      instructor</a>
    <ng-template #logged>
      <!--<button class="btn btn-light px-5 py-2" (click)="openDialog()">Convierte en
        instructor</button>-->
      <a class="btn" (click)="openDialog()">Conviértete en
        instructor</a>
    </ng-template>
  </div>
</section>

<section class="about" id="about">
  <div class="row">
    <div class="col50">
      <h1 class="titleText">¡VISUALIZA<span> TU ÉXITO!</span></h1>
      <h1>Planifica tu curso</h1>
      <p>¡Organiza y empieza! Sabemos tu pasión por la enseñanza y el infinito conocimiento que tienes para entregar.
        <br><br>
        Elige un tema y elabora tu curso, no olvides cada curso es un conjunto de capítulos grabados, y su material de
        apoyo.
        <br><br>
        ¡Si! podrás interactuar con ellos por un foro y crear tus evaluaciones parametrizadas y al finalizar entregar a
        cada alumno un certificado digital por la satisfacción de haber cumplido con el curso.
      </p>
    </div>
    <div class="col50">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-10.jpeg?alt=media&token=99b6e014-fc46-4874-a88d-d4e97d8ac1c7"
          alt="">
      </div>
    </div>
    <div class="col50 py-5">
      <div class="imgBx">
        <!--<img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/images%2F9.png?alt=media&token=91bbd729-2a4e-4dbc-844f-4bf1ec8d045c"
          alt="">-->
        <video controls
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/videos_Instructor%2F3_oficial.mp4?alt=media&token=22ab10c3-cace-49aa-9719-e586cbe7b38e"></video>
      </div>
    </div>
    <div class="col50">
      <h1 class="titleText">ORGANIZA TU CURSO <br><span>¡GRABA VIDEOS DE TUS CLASES!</span></h1>
      <p>Sabemos tu pasión por la enseñanza y la experticia de tu conocimiento, entonces a comenzar con la creación de
        tu curso.
        <br><br>
        <span>¿Cómo te ayudamos?</span>
        <br>
        Exponemos tus cursos en una plataforma especializada de aprendizaje online, con hojas de trabajo recursos y
        material que dispongas para apoyar a tus alumnos.
        <br><br>
        <span>¿Tienes un teléfono inteligente o una DSLR?</span> Agregue un micrófono y estará listo para filmar su
        primer curso
        desde su casa o donde sea que se encuentre.
        <br><br>
        Además, nuestro panel del instructor es intuitivo y te ayuda a mantenerte encaminado. ¡Luces, CAMARA, ACCION!
      </p>
    </div>
    <div class="col50">
      <h1 class="titleText">CONSTRUYE TU <span>COMUNIDAD</span></h1>
      <p>La Academia Co Marca te da la oportunidad de crear tu propia COMUNIDAD al exponer tus cursos a través de
        nuestro FORO INTERACTIVO por cada clase donde podrás interactuar de manera directa con cada uno de tus alumnos,
        escribir mensajes y responder a cada una de sus preguntas. ¡Porque los estudiantes, necesitan y esperan un
        <span>FEEDBACK</span> de su profesor!
      </p>
    </div>
    <div class="col50 py-5">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-3.jpeg?alt=media&token=f6574c4e-89a7-4004-93d5-216a7121464f"
          alt="">
      </div>
    </div>
  </div>
</section>
<section class="experts" id="experts">
  <div class="title">
    <h2 class="titleText">DESCUBRE TU <span> POTENCIAL</span></h2>
  </div>
  <div class="content">
    <div class="box">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/images%2FPROF.png?alt=media&token=ce2d3916-02bc-4949-9477-89d84d2b6f9c"
          alt="">
      </div>
      <div class="text">
        <h2 class="titleText">Sé parte de nuestra comunidad</h2>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/images%2FPROF2.png?alt=media&token=6030d41c-9e25-465a-a199-39274b377b25"
          alt="">
      </div>
      <div class="text">
        <h2 class="titleText">Inspira a tus estudiantes</h2>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/images%2FPROF3.png?alt=media&token=d8c3f594-2661-4318-b508-d9b2d560f2c7"
          alt="">
      </div>
      <div class="text">
        <h2 class="titleText">Gana tu dinero</h2>
      </div>
    </div>
  </div>
</section>

<!--<section class="testimonials" id="testimonials">
  <div class="title white">
    <h1 class="titleText">EXPANDE <span> TU ALCANCE!</span></h1>
  </div>
  <div class="content">
    <div class="box">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/images%2Fprofile-3.jpg?alt=media&token=164bc1dd-7be3-4ca3-a9ec-9389647cb710"
          alt="">
      </div>
      <div class="text">
        <p>“Lo que más me gusta de la Academia es las herramientas de gestión para instructores y alumnos, la
          comunicación directa de profesor alumno y el trato directo que
          hay”.</p>
        <h3>Mauricio López</h3>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/images%2Fprofile-pic1.jpg?alt=media&token=087de49e-83ac-4072-8db5-30a473fe0784"
          alt="">
      </div>
      <div class="text">
        <p>“Es muy fácil de usar tanto para estudiantes como para profesores. Y te deja gestionar totalmente el
          contenido. Además me permite decidir el precio y comunicarme fácilmente con mis
          estudiantes”.</p>
        <h3>Leticia Rodriguez</h3>
      </div>
    </div>
    <div class="box">
      <div class="imgBx">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/images%2Fprofile-4.jpg?alt=media&token=4c0b9f41-272b-47d5-b222-4a99ca0e0044"
          alt="">
      </div>
      <div class="text">
        <p>“Es muy fácil de usar tanto para estudiantes como para profesores. Y te deja gestionar totalmente el
          contenido. Además me permite decidir el precio y comunicarme fácilmente con mis
          estudiantes”.</p>
        <h3>Daniel Navarro</h3>
      </div>
    </div>
  </div>
</section>-->
