import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { EstudianteComponent } from './components/estudiante/estudiante.component';
import { MisCursosAdquiridosComponent } from './components/mis-cursos-adquiridos/mis-cursos-adquiridos.component';


const routes: Routes = [
  {
    path: 'mis-cursos-adquiridos',
    component: MisCursosAdquiridosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'mi-perfil',
    component: EstudianteComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EstudianteRoutingModule { }
