<div class="container-fluid body">
  <div class="row justify-content-center text-align-center title-content">
    <div class="col-md-12 py-5 text-white text-center">
      <p class="text-white text-align-center lead">Conoce a nuestros instructores</p>
    </div>
  </div>
</div>
<hr>
<div *ngIf="estado">
  <div class="container">
    <div class="alert alert-primary" role="alert">
      Se parte de nuestra comunidad. <a routerLink="/instructores/pasos-instructor">Ingresa aquí</a>
    </div>
  </div>
</div>
<div class="container content">
  <!--Presentacion del usuario-->
  <div class="row justify-content-center" role="status">
    <!--<div *ngIf="!docente" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>-->
    <div class="col-md-3" *ngFor="let item of docente">
      <!--Cards de los nuevos cursos-->
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>
            <a class="lead" routerLink="/instructores/presentacion-docente/{{ item.id_usuario }}">{{ item.nombre_usuario
              }}</a>
          </mat-card-title>
        </mat-card-header>
        <div>
          <hr>
        </div>
        <img mat-card-image src="{{item.foto_usuario}}"
          routerLink="/instructores/presentacion-docente/{{ item.id_usuario }}" class="img-cards" fallimg="avatar" />
        <mat-card-subtitle>{{item.correo_usuario}}</mat-card-subtitle>
        <mat-card-content *ngIf="item.datos_docente">
          <div class="container" [ngClass]="{'long-text': texto}" (click)="texto = !texto">
            {{item.datos_docente.descripcion_docente}}
          </div>
        </mat-card-content>
      </mat-card>
      <br>
      <!--Final de los Cards - nuevos cursos-->
    </div>
  </div>
</div>
