<mat-dialog-content class="mat-typography">
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>remember_me</mat-icon><h2>Datos Personales</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="formPago" (ngSubmit)="submitEditarUsuario($event)" class="example-form">
        <!--Campos del formulario-->
        <!--Nombre completo del estudiante-->
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Nombre Completo</mat-label>
          <input matInput formControlName="nombre_usuario" type="text">
        </mat-form-field>
        <!--Correo electronico del estudiante-->
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Correo electrónico</mat-label>
          <input matInput formControlName="correo_usuario" type="email">
        </mat-form-field>
        <!--Final del formmulario de registro-->
        <div class="col-12" align="end">
          <button mat-flat-button color="primary" class="col-12" type="submit">Edita tus datos</button>
        </div>
      </form>
      <mat-action-row>
        <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>shopping_cart</mat-icon><h2>Comprobante de pago</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-pago-deposito [id]="data.id">
      </app-pago-deposito>
      <br>
      <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Anterior</button>
      </mat-action-row>
      <br>
    </mat-expansion-panel>
  </mat-accordion>
  <br>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
