<!--Spinner de carga del formulario-->
<mat-spinner *ngIf="isActive"></mat-spinner>
<!--Contenido del formulario-->
<div *ngIf="!isActive">
  <!--Posicionamiento del boton para cancelar o cerrar el formulario-->
  <div class="d-flex justify-content-end">
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!--Posicionamiento del boton para cancelar o cerrar el formulario-->

  <!--Titulo del formulario-->
  <div class="row">
    <h1 mat-dialog-title>Formulario de solicitud para instructores</h1>
  </div>
  <!--Titulo del formulario-->

  <!--Contenido del dialogo o modal-->
  <mat-dialog-content>

    <!--Formulario de la solicitud-->
    <form [formGroup]="formDocente" (ngSubmit)="submitRegistrarDocente($event)" class="example-form">
      <!--Grupo de tabs-->
      <mat-tab-group mat-align-tabs="center">

        <!--Pirmer tab: Informacion del usuario-->
        <mat-tab label="Tus datos de usuario"><br>
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Tu nombre completo:</mat-label>
            <input matInput value="{{ datosUsuario.nombre_usuario }}" [readonly]="true" />
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Correo electrónico:</mat-label>
            <input matInput value="{{ datosUsuario.correo_usuario }}" [readonly]="true" />
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Tu número de celular:</mat-label>
            <input matInput formControlName="telefono_docente" maxlength="8" />
          </mat-form-field>
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Tu experiencia como instructor / docente:</mat-label>
            <input matInput formControlName="experiencia_docente" required />
          </mat-form-field>
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Háblanos acerca de ti (Descripción del instructor):</mat-label>
            <textarea matInput formControlName="descripcion_docente" #input maxlength="180"
              placeholder="Ex. Soy una persona..." required></textarea>
            <!--<mat-hint align="end">{{input.value?.length || 0 }} / 180</mat-hint>-->
          </mat-form-field>
        </mat-tab>
        <!--Fin del primer tab-->

        <!--Segundo tab: Informacion bancaria del usuario-->
        <mat-tab label="Información adicional"><br>
          <div class="alert alert-warning" role="alert">
            Con los datos de tu cuenta bancaria, TigoMoney, etc. Podremos depositarte las ganancias de tus cursos
            expuestos.
          </div>
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Banco de origen: ej. Banco BNB, BCP, Mercantil SantaCruz, etc.</mat-label>
            <input matInput formControlName="nombre_banco" required />
          </mat-form-field>
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Tipo de Cuenta: ej. Caja de ahorros, corriente, etc.</mat-label>
            <input matInput formControlName="tipo_cuenta" required />
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Número de Cuenta:</mat-label>
            <input matInput formControlName="numero_cuenta" />
          </mat-form-field>
          <mat-form-field class="col-md-6" appearance="outline">
            <mat-label>Carnet de Identidad:</mat-label>
            <input matInput formControlName="carnet_identidad" />
          </mat-form-field>
        </mat-tab>
        <!--Fin del segundo tab-->

        <!--Tercer tab: carga de archivos (video de presentacion y hoja de vida CV)-->
        <mat-tab label="Tu vídeo de presentación y hoja de Vida"><br>
          <div class="alert alert-warning" role="alert">
            Tu hoja de vida es importante para nosostros. Sin embargo, el envío de tu video de presentación es
            opcional. <br>
            Selecciona tus archivos correspondientes para enviar tu solicitud. Sólo debes elegir el archivo y espera a
            que la carga finalice para poder enviarlo.
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <!--Carga de video de presentacion-->
                <h3>Carga tu video</h3>
                <p class="download" *ngIf="finalizado">URL de descarga: <a href="{{ filedata }}"
                    target="_blank">Descargar</a></p>
                <div *ngIf="nombreArchivo" class="alert alert-success" role="alert">
                  Archivo preparado: {{nombreArchivo}}
                </div>
                <input class="file-input" type="file" #video hidden accept="video/mp4,video/x-m4v,video/*"
                  (change)="cambioArchivo($event)" name="video_presentacion" />
                <div class="row">
                  <div class="container">
                    <mat-progress-bar *ngIf="porcentaje > 0 && porcentaje < 100" value="{{porcentaje}}">
                      {{porcentaje}}%</mat-progress-bar><br>
                    <button type="button" mat-flat-button color="primary" class="col-md-12" (click)="video.click()">
                      Selecciona tu video <mat-icon>video_call</mat-icon>
                    </button>
                  </div>
                </div>
                <!--Carga de video de presentacion-->
              </div>
              <div class="col-md-6">
                <!--Carga de hoja de vida-->
                <h3>Carga tu hoja de vida CV</h3>
                <p class="download" *ngIf="finalizadoCv">URL de descarga: <a href="{{ filedataCV }}"
                    target="_blank">Descargar</a></p>
                <div *ngIf="nombreArchivoCv" class="alert alert-success" role="alert">
                  Archivo preparado: {{nombreArchivoCv}}
                </div>
                <input type="file" name="cv_docente" #cv hidden
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="changeCv($event)" />
                <div class="row">
                  <div class="container">
                    <mat-progress-bar *ngIf="porcentajeCv > 0 && porcentajeCv < 100" value="{{ porcentajeCv }}">
                      {{ porcentajeCv }}%
                    </mat-progress-bar><br>
                    <button mat-flat-button class="col-md-12" color="accent" type="button" (click)="cv.click()">
                      Selecciona tu hoja de vida CV <mat-icon>note_add</mat-icon>
                    </button>
                  </div>
                </div>
                <!--Carga de hoja de vida-->
              </div>
            </div>
          </div>
          <!--Final del tercer tab-->
          <hr>
          <!--Boton de envio de la solicitud-->
          <button mat-raised-button color="primary" type="submit" *ngIf="filedataCV" [disabled]="formDocente.invalid">
            Enviar mi solicitud!
          </button>
          <!--Boton de envio de la solicitud-->
        </mat-tab>
      </mat-tab-group>
      <!--Grupo de tabs-->
    </form>
    <!--Formulario de la solicitud-->
  </mat-dialog-content>
  <!--Contenido del dialogo o modal-->

  <!--Boton de cancelar-->
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]>Cancelar</button>
  </mat-dialog-actions>
  <!--Boton de cancelar-->

</div>
<!--Final del contenido del formulario-->
