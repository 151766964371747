import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pago-mone',
  templateUrl: './pago-mone.component.html',
  styleUrls: ['./pago-mone.component.scss']
})
export class PagoMoneComponent implements OnInit {

  formAdquirirCurso: FormGroup;
  respuesta: any;
  isActive = false;
  //@Input()
  id: any;
  idD: any;
  enlace: any;
  estadoCargando = false;

  constructor(
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<PagoMoneComponent>,
    public cursoAd: UsuarioService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.id = data[0];
    this.idD = data[1];
    //console.log(this.idD);
  }

  ngOnInit(): void {
    this.buildForm();
    //console.log(this.id);
  }

  buildForm(): void {
    this.formAdquirirCurso = this.formBuilder.group({
      id_curso: [''],
      id_usuario: [''],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  //PAGO MONE
  datosMoneCurso(event: Event): void {
    event.preventDefault();
    this.estadoCargando = true;
    //console.log(this.formAdquirirCurso.value);
    const myFormData = new FormData();
    const datos = JSON.parse(localStorage.getItem('datosUsuario'));
    const id = datos.id_usuario;
    myFormData.append('id_usuario', id);
    myFormData.append('id_curso', this.id);
    //console.log(this.id);
    this.cursoAd.moneAdC(myFormData).subscribe(res => {
      this.respuesta = res;
      //console.log(this.respuesta);
      this.estadoCargando = false;
      this.enlace = this.respuesta.urlpago;
      if (this.enlace) {
        const url = this.enlace;
        window.open(url, "_blank");
        this.onNoClick();
      }
      Swal.fire({
        title: 'Enlace de Pago Mone',
        text: this.respuesta.mensaje,
        icon: this.respuesta.estado
      });
    }, error => {
      //console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Algo salió mal',
        text: 'Inténtelo más tarde',
      });
    });
  }
} 
