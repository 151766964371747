<section id="footer">
  <img src="../../../../assets/img/wave2.png" class="footer-img" alt="">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h4>De emprendedores para emprendedores.</h4>
        <P>Calacoto, Calle 12 y Av. Fuerza Naval</P>
        <p>Pasaje Inofuentes #502.</p>
      </div>
      <div class="col-md-4">
        <div class="icons">
          <a href="https://www.facebook.com/Academia-Co-Marca-705715713602577/" target="_blank"><i
              class="fa fa-facebook"></i></a>
          <a href="https://www.youtube.com/channel/UCWz0S-q6Uzu_sUS7luevttw/featured" target="_blank"><i
              class="fa fa-youtube"></i></a>
          <a href="https://www.instagram.com/academia_co_marca/?hl=es" target="_blank"><i
              class="fa fa-instagram"></i></a>
        </div>
        <div>
          <p>
            © 2021, By Academia Co Marca</p>
        </div>
        <div>
          <a href="../../../../assets/archivos/Politicas de privacidad Academia Co Marca 2022.pdf" target="_blank">
            <p>Política de Privacidad</p>
          </a>
          <a href="../../../../assets/archivos/Terminos y Condiciones de Uso plataforma Academia Co Marca 2022.pdf"
            target="_blank">
            <p>Condiciones</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
