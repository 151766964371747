<div class="container-fluid body">
  <div class="row justify-content-center text-align-center title-content">
    <div class="col-md-12 py-5 text-white text-center">
      <p class="text-white text-align-center lead">Nuestros Cursos</p>
    </div>
  </div>
</div>
<hr>
<div class="container content">
  <div *ngIf="estado">
    <div class="alert alert-primary col-12">
      <h4 class="mensaje">Construyendo cursos de alto valor para un aprendizaje de excelencia</h4>
    </div>
  </div>
  
  <div class="row justify-content-center" role="status">
    <div *ngIf="!curso" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>

    <div class="card col-lg-3 col-md-4 col-sm-12 p-2 m-2 mx-auto example-card" *ngFor="let item of curso">
      <mat-card routerLink="/curso/curso-detalle/{{ item.id_curso }}">
        <!--routerLink="/curso/curso-detalle/{{ item.id_curso }}"-->
        <img mat-card-image src="{{ item.imagen_curso }}"
          class="img-cards" fallimg />
        <!--Linea debajo de la imagen-->
        <hr>
        <!--Cabecera del card: Nombre y Precio del Curso -->
        <mat-card-header>
          <mat-card-title>
            <a class="lead titulo">{{ item.nombre_curso }}</a>
          </mat-card-title>
          <br>
          <mat-card-subtitle>Precio: Bs. {{ item.precio}}</mat-card-subtitle>
        </mat-card-header>
        <!--<mat-card-subtitle>Descripción: </mat-card-subtitle>-->
        <!--Cabecera del card: Nombre y Precio del Curso -->
        <!--Contenido del card: Descripcion del Curso -->
        <mat-card-content>
          <div class="container" [ngClass]="{'long-text': texto}" (click)="texto = !texto">
            {{ item.descripcion_curso }}
          </div>
        </mat-card-content>
        <!--Contenido del card: Descripcion del Curso -->
        <div class="container">
          <div class="row" *ngIf="item.etiquetas_curso">
            <!--<mat-card-subtitle>Categorias pertenecientes:</mat-card-subtitle>-->
            <div *ngFor="let etiqueta of item.etiquetas_curso" class="col-md">
              <a routerLink="/curso/cursos-categorias/{{etiqueta.id_etiqueta}}">{{etiqueta.nombre_etiqueta}}</a>
            </div>
          </div>
        </div>
        <!--<mat-chip-list align="center" aria-label="Fish selection">
          <mat-chip-list class="mat-chip-list-stacked" aria-label="Color selection"
            *ngFor="let etiqueta of item.etiquetas_curso">
            <mat-chip routerLink="/curso/cursos-categorias/{{etiqueta.id_etiqueta}}" class="mat-chip" selected>
              {{etiqueta.nombre_etiqueta}}
            </mat-chip>
          </mat-chip-list>
        </mat-chip-list>-->
      </mat-card>
    </div>
  </div>
</div>
