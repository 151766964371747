<div class="body">
  <!-- <section id="home" class="container-fluid">
    <div class="row align-items-center min-vh-100 content">
      <div class="col-md-12 col-md-2 text-center text-md-right">
        <h2 class="section-title">Amplía tus</h2>
        <h1 class="section-title">habilidades</h1>
        <h3 class="section-title">e interactúa con nuestros cursos en línea.</h3>
        <button routerLink="/instructores/pasos-instructor"> Sé instructor </button>-->
  <!--<h2 class="section-title">Comparte conocimientos,</h2>
        <h1 class="section-title">crea una comunidad</h1>
        <h3 class="section-title">sin límite y genera ingresos con lo que te apasiona.</h3>
        <button routerLink="/instructores/pasos-instructor"> Sé instructor </button>
      </div>
      </div>
</section>-->

  <section id="banner">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-center text-md-right content">
          <h3 class="section-title">Comparte conocimientos, crea una comunidad</h3>
          <h3 class="section-title">sin límite y genera ingresos con lo que te apasiona.</h3>
          <button routerLink="/instructores/pasos-instructor"> Sé instructor </button>

        </div>
        <div class="col-md-6 text-center">
          <div class="container">
            <div class="sliding-background"></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <img src="../../../../../assets/img/wave1.png" alt="">
    </div>
  </section>

  <div *ngIf="!cursos">
    <!--Listado de Cursos en general-->
    <section>
      <div class="site-section courses-title" id="courses-section">
        <div class="container">
          <div class="row mb-5 justify-content-center">
            <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
              <h2 class="section-title">Cursos</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">
        <div class="row justify-content-center">
          <!--Spinner de carga-->
          <div *ngIf="!curso" class="col-12">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="police-product">
      <div class="container">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of curso; let i = index">
            <ng-template carouselSlide [id]=" item.id">
              <div class="course bg-white align-self-stretch">
                <figure class="m-0">
                  <img routerLink="/curso/curso-detalle/{{ item.id_curso }}" src="{{ item.imagen_curso }}" alt="Image"
                    class="img-cards" fallimg>
                </figure>
                <div class="course-inner-text py-4 px-4">
                  <span class="course-price">Bs. {{item.precio}}</span>
                  <div class="meta"><span>
                      <mat-icon>library_books</mat-icon>
                    </span>{{item.modulos_curso.length}} módulos</div>
                  <h3><a routerLink="/curso/curso-detalle/{{ item.id_curso }}">{{ item.nombre_curso }}</a></h3>
                  <p [ngClass]="{'long-text': texto}" (click)="texto = !texto" align="justify">{{
                    item.descripcion_curso
                    }}</p>
                  <!--<div class="container descripcion" [ngClass]="{'long-text': texto}" (click)="texto = !texto">
                    {{ item.descripcion_curso }}
                  </div>-->
                </div>
                <!--<div class="d-flex border-top stats">
                  <div class="py-4 px-4"><span class="icon-users">
                      <mat-icon>groups</mat-icon>
                    </span> {{ item.curso_estudiante.length}} estudiantes</div>
                  <div class="py-3 px-4 w-25 ml-auto border-left"><span class="icon-chat">
                      <mat-icon>comment</mat-icon>
                    </span> 2</div>
                </div>-->
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>
  </div>

  <section>
    <div class="site-section courses-title" id="courses-section">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
            <h2 class="section-title">Aprende con nosotros</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">
      <div class="row justify-content-center py-5">
        <video
          src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/Videoa%2FVideo%201%20oficial.mp4?alt=media&token=21b42fe3-31d5-4369-9a1d-4076e3d3ca81"
          controls></video>
      </div>
    </div>
  </section>

  <section class="cta">
    <h2 class="section-title">¿Tienes preguntas?</h2>
    <div class="container">
      <mat-accordion multi>
        <!--first as isFirst ;-->
        <mat-expansion-panel *ngFor="let item of pregunta, let i = index;" (opened)="panelOpenState = true"
          (closed)="panelOpenState = false" [expanded]="isFirst">
          <mat-expansion-panel-header>
            <!-- first as isFirst;-->
            <mat-panel-title>
              <h3> {{i+1}}. {{item.texto_pregunta}}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <hr class="hr">
          <div class="row" *ngFor="let res of  item.pregunta_respuesta" align="justify">
            <!--<mat-icon color="warn">done_all </mat-icon>-->
            <h3>- {{ res.texto_respuesta_pregunta }}</h3>
          </div>
          <!--<mat-list *ngFor="let res of  item.pregunta_respuesta">
            <!--reemplazar en lugar del ngfor de arriba--
                  *ngFor="let res of item.texto_respuesta_pregunta"--
            <mat-list-item role="listitem" align="justify">
                <mat-icon color="warn">done_all </mat-icon>
                <h3 class="res">{{ res.texto_respuesta_pregunta }}</h3>
            </mat-list-item>
          </mat-list>-->
          <br>
        </mat-expansion-panel>
        <br>
      </mat-accordion>
      <br>
    </div>
  </section>
  <!--Final Listado de preguntas frecuentes-->

  <section>
    <div class="site-section courses-title" id="courses-section">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
            <h2 class="section-title">¿Por qué estudiar en Academia Co Marca?</h2>
          </div>
        </div>
      </div>
    </div>

    <!--Carousel ventajas-->
    <div id="slider" class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">
      <div class="container py-5">
        <div class="row justify-content-center">
          <!--<img src="./assets/img/Inicio/10.png" class="ventajas" alt="ventajas" />-->
          <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-7.jpeg?alt=media&token=3e9871b1-d6d9-4811-a480-297c91c96cd6"
                  class="d-block w-100" alt="">
              </div>
              <div class="carousel-item">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-6.jpeg?alt=media&token=c2665b5c-4a51-4a27-b203-0a9bfec9ca79"
                  class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-5.jpeg?alt=media&token=187d8c1c-392c-474f-8749-0162b28fcef7"
                  class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/carousel%2Fcarousel-4.jpeg?alt=media&token=c4197a0d-7aa9-466c-9931-6b8e6ce48885"
                  class="d-block w-100" alt="...">
              </div>
              <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only ">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only ">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--<div *ngIf="!etiq">
    <!--Categorias de la Plataforma--
    <div class="site-section courses-title" id="courses-section">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
            <h2 class="section-title">Categorías</h2>
          </div>
        </div>
      </div>
    </div>
    <section class="campus">
      <div class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">
        <div class="row justify-content-center">
          <div class="col-md-4" *ngFor="let item of etiqueta">
            <div class="campus-col" routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">
              <img src="{{item.imagen_etiqueta}}" alt="">
              <div class="layer">
                <h3 routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">{{item.nombre_etiqueta}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let item of etiqueta; let i = index">
          <ng-template carouselSlide [id]=" item.id">
            <div class="campus-col" routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">
              <img src="{{item.imagen_etiqueta}}" alt="">
              <div class="layer">
                <h3 routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">{{item.nombre_etiqueta}}</h3>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>--
    </section>-->
  <!--Final Categorias de la Plataforma--
  </div>-->

  <div *ngIf="!etiq">
    <!--Categorias de la Plataforma-->
    <div class="site-section courses-title" id="courses-section">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
            <h2 class="section-title">Categorías</h2>
          </div>
        </div>
      </div>
    </div>
    <section class="campus">
      <div class="site-section courses-entry-wrap" data-aos="fade-up" data-aos-delay="100">
        <!--<div class="row justify-content-center">
          <div class="col-md-3" *ngFor="let item of etiqueta">
            <div class="campus-col" routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">
              <img src="{{item.imagen_etiqueta}}" alt="">
              <div class="layer">
                <h3 routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">{{item.nombre_etiqueta}}
                </h3>
              </div>
            </div>
          </div>
        </div>--->
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of etiqueta">
            <ng-template carouselSlide [id]=" item.id">
              <div class="campus-col" routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">
                <img src="{{item.imagen_etiqueta}}" alt="">
                <div class="layer">
                  <h3 routerLink="/curso/cursos-categorias/{{item.id_etiqueta}}">{{item.nombre_etiqueta}}</h3>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>
    <!--Final Categorias de la Plataforma-->
  </div>

  <!--Seccion de instructores--
  <div *ngIf="!inst">
    <section class="testimonials" id="testimonials">
      <div class="container py-5">
        <div class="row mb-5 justify-content-center">
          <div class="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
            <h2 class="section-title text-white">Instructores</h2>
          </div>
        </div>
      </div>
      <div class="content" data-aos="fade-up" data-aos-delay="100">
        <div *ngFor="let doc of docente; let i = index">
          <div class="box" *ngIf="i < 6">
            <div class="imgBx">
              <img src="{{ doc?.foto_usuario }}" routerLink="/docente/presentacion-docente/{{ doc.id_usuario }}"
                fallimg="avatar">
            </div>
            <div class="text">
              <p>{{ doc.datos_docente?.descripcion_docente }}</p>
              <p>{{ doc.datos_docente?.experiencia_docente }}</p>
              <h3 routerLink="/instructores/presentacion-docente/{{ doc.id_usuario }}">{{doc.nombre_usuario }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>-->
  <!--Final de la seccion de instructores-->
</div>
