<!--Posicionamiento del boton para cancelar o cerrar el formulario-->
<div class="d-flex justify-content-end">
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>
<!--Posicionamiento del boton para cancelar o cerrar el formulario-->
<!--Titulo del formulario-->
<div class="row">
  <h1 mat-dialog-title>Formulario de compra por tarjeta de crédito/débito</h1>
</div>
<!--Titulo del formulario-->

<!--Contenido del dialogo o modal-->
<mat-dialog-content>
  <form
    [formGroup]="formPagoTarjeta"
    (ngSubmit)="linkser($event)"
    class="example-form"
  >
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Monto</mat-label>
      <input matInput readonly formControlName="amount" type="text" required />
    </mat-form-field>
    <mat-tab-group mat-align-tabs="center">
      <!--Datos del usuario-->
      <mat-tab label="Datos de Facturación"
        ><br />
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Nombre</mat-label>
          <input
            matInput
            formControlName="bill_to_forename"
            placeholder="Ej. Anna Maria"
            type="text"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Apellido</mat-label>
          <input
            matInput
            formControlName="bill_to_surname"
            placeholder="Velasquez Mendoza"
            type="text"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Pais</mat-label>
          <select
            matNativeControl
            formControlName="bill_to_address_country"
            required
          >
            <option disabled value="">seleccione uno ↓</option>
            <br />
            <option value="BO">BOLIVIA</option>
          </select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Ciudad</mat-label>
          <select
            matNativeControl
            formControlName="bill_to_address_state"
            required
          >
            <option disabled value="">seleccione uno ↓</option>
            <br />
            <option value="L">La Paz</option>
            <option value="C">Cochabamba</option>
            <option value="S">Santa Cruz</option>
            <option value="O">Oruro</option>
            <option value="T">Tarija</option>
            <option value="P">Potosi</option>
            <option value="N">Pando</option>
            <option value="H">Chuquisaca</option>
            <option value="B">Beni</option>
          </select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Direccion</mat-label>
          <input
            maxlength="28"
            matInput
            formControlName="bill_to_address_line1"
            placeholder="Av. Los Rosales"
            type="text"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Numero Telefonico</mat-label>
          <input
            matInput
            formControlName="bill_to_phone"
            placeholder="1111 2222 3333"
            type="text"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-12">
          <mat-label>Correo Electronico</mat-label>
          <input
            matInput
            formControlName="bill_to_email"
            placeholder="example@email.com"
            type="text"
            required
          />
        </mat-form-field>
      </mat-tab>
      <!--Datos del usuario-->

      <!--Pago-->
      <mat-tab label="Pago"
        ><br />
        <div class="container">
          <mat-radio-group
            aria-label="Select an option"
            formControlName="card_type"
          >
            <mat-radio-button
              value="001"
              class="col-md-6 justify-content-center"
              >VISA</mat-radio-button
            >
            <mat-radio-button
              value="002"
              class="col-md-6 justify-content-center"
              >MasterCard</mat-radio-button
            >
            <br />
          </mat-radio-group>
        </div>
        <mat-form-field appearance="outline" class="col-md-12">
          <mat-label>Numero de Tarjeta</mat-label>
          <input
            matInput
            formControlName="card_number"
            placeholder="Ingresa el número de tu tarjeta"
            type="text"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Mes</mat-label>
          <select
            matNativeControl
            formControlName="card_expiry_datemes"
            required
          >
            <option disabled value="">Mes</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>Año</mat-label>
          <select
            matNativeControl
            formControlName="card_expiry_dateyear"
            required
          >
            <option disabled value="">Año</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
          </select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-12">
          <mat-label>CVN</mat-label>
          <input
            matInput
            formControlName="card_cvn"
            placeholder="Ingresa el código que se encuentra detrás de tu tarjeta"
            type="password"
            required
          />
        </mat-form-field>
        <mat-card-actions>
          <button
            mat-raised-button
            class="col-md-12"
            color="primary"
            type="submit"
            [disabled]="formPagoTarjeta.invalid"
            *ngIf="!showButton"
          >
            Continuar
          </button>
        </mat-card-actions>
      </mat-tab>
      <!--Pago-->

      <!--Recibo-->
      <mat-tab label="Recibo">
        <br />
        <form class="formrecibo" ngNoForm *ngIf="respuesta">
          <div class="uno">
            <label>Nombre(s): </label>
            <h4>{{ respuesta.data.bill_to_forename }}</h4>
            <label>Apellido(s): </label>
            <h4>{{ respuesta.data.bill_to_surname }}</h4>
            <label>Correo Electrónico: </label>
            <h4>{{ respuesta.data.bill_to_email }}</h4>
            <label>Número de Referencia: </label>
            <h4>{{ respuesta.data.bill_to_phone }}</h4>
          </div>
          <div class="dos">
            <label>Dirección: </label>
            <h4>{{ respuesta.data.bill_to_address_line1 }}</h4>
            <label>Ciudad: </label>
            <h4>{{ respuesta.data.bill_to_address_city }}</h4>
            <label>Número de Tarjeta:</label>
            <h4>{{ respuesta.data.card_number }}</h4>
            <label>Fecha de Expiración:</label>
            <h4>{{ respuesta.data.card_expiry_date }}</h4>
          </div>
          <hr />
        </form>
        <br />
        <form
          ngNoForm
          *ngIf="respuesta"
          action="https://secureacceptance.cybersource.com/silent/pay/"
          method="POST"
        >
          <input
            style="display: none"
            type="text"
            name="access_key"
            id="access_key"
            value="{{ respuesta.data.access_key }}"
          />
          <input
            style="display: none"
            type="text"
            name="profile_id"
            id="profile_id"
            value="{{ respuesta.data.profile_id }}"
          />
          <input
            style="display: none"
            type="text"
            name="transaction_uuid"
            id="transaction_uuid"
            value="{{ respuesta.data.transaction_uuid }}"
          />
          <input
            style="display: none"
            type="text"
            name="signed_field_names"
            id="signed_field_names"
            value="{{ respuesta.data.signed_field_names }}"
          />
          <input
            style="display: none"
            type="text"
            name="unsigned_field_names"
            id="unsigned_field_names"
            value="{{ respuesta.data.unsigned_field_names }}"
          />
          <input
            style="display: none"
            type="text"
            name="signed_date_time"
            id="signed_date_time"
            value="{{ respuesta.data.signed_date_time }}"
          />
          <input
            style="display: none"
            type="text"
            name="locale"
            id="locale"
            value="{{ respuesta.data.locale }}"
          />
          <input
            style="display: none"
            type="text"
            name="transaction_type"
            id="transaction_type"
            value="{{ respuesta.data.transaction_type }}"
          />
          <input
            style="display: none"
            type="text"
            name="reference_number"
            id="reference_number"
            value="{{ respuesta.data.reference_number }}"
          />
          <input
            style="display: none"
            type="text"
            name="amount"
            id="amount"
            value="{{ respuesta.data.amount }}"
          />
          <input
            style="display: none"
            type="text"
            name="currency"
            id="currency"
            value="{{ respuesta.data.currency }}"
          />
          <input
            style="display: none"
            type="text"
            name="payment_method"
            id="payment_method"
            value="{{ respuesta.data.payment_method }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_forename"
            id="bill_to_forename"
            value="{{ respuesta.data.bill_to_forename }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_surname"
            id="bill_to_surname"
            value="{{ respuesta.data.bill_to_surname }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_email"
            id="bill_to_email"
            value="{{ respuesta.data.bill_to_email }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_phone"
            id="bill_to_phone"
            value="{{ respuesta.data.bill_to_phone }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_address_line1"
            id="bill_to_address_line1"
            value="{{ respuesta.data.bill_to_address_line1 }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_address_city"
            id="bill_to_address_city"
            value="{{ respuesta.data.bill_to_address_city }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_address_state"
            id="bill_to_address_state"
            value="{{ respuesta.data.bill_to_address_state }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_address_country"
            id="bill_to_address_country"
            value="{{ respuesta.data.bill_to_address_country }}"
          />
          <input
            style="display: none"
            type="text"
            name="bill_to_address_postal_code"
            id="bill_to_address_postal_code"
            value="{{ respuesta.data.bill_to_address_postal_code }}"
          />
          <input
            style="display: none"
            type="text"
            name="device_fingerprint_id"
            id="device_fingerprint_id"
            value="{{ respuesta.data.device_fingerprint_id }}"
          />
          <input
            style="display: none"
            type="text"
            name="merchant_defined_data7"
            id="merchant_defined_data7"
            value="{{ respuesta.data.merchant_defined_data7 }}"
          />
          <input
            style="display: none"
            type="text"
            name="merchant_defined_data9"
            id="merchant_defined_data9"
            value="{{ respuesta.data.merchant_defined_data9 }}"
          />
          <input
            style="display: none"
            type="text"
            name="merchant_defined_data14"
            id="merchant_defined_data14"
            value="{{ respuesta.data.merchant_defined_data14 }}"
          />
          <input
            style="display: none"
            type="text"
            name="merchant_defined_data87"
            id="merchant_defined_data87"
            value="{{ respuesta.data.merchant_defined_data87 }}"
          />
          <input
            style="display: none"
            type="text"
            name="merchant_defined_data88"
            id="merchant_defined_data88"
            value="{{ respuesta.data.merchant_defined_data88 }}"
          />
          <input
            style="display: none"
            type="text"
            name="merchant_defined_data90"
            id="merchant_defined_data90"
            value="{{ respuesta.data.merchant_defined_data90 }}"
          />
          <input
            style="display: none"
            type="text"
            name="card_type"
            id="card_type"
            value="{{ respuesta.data.card_type }}"
          />
          <input
            style="display: none"
            type="text"
            name="card_number"
            id="card_number"
            value="{{ respuesta.data.card_number }}"
          />
          <input
            style="display: none"
            type="text"
            name="card_expiry_date"
            id="card_expiry_date"
            value="{{ respuesta.data.card_expiry_date }}"
          />
          <input
            style="display: none"
            type="text"
            name="card_cvn"
            id="card_cvn"
            value="{{ respuesta.data.card_cvn }}"
          />
          <input
            style="display: none"
            type="text"
            name="signature"
            id="signature"
            value="{{ respuesta.data.signature }}"
          />
          <button
            mat-raised-button
            class="col-md-12"
            color="primary"
            type="submit"
          >
            Pagar
          </button>
        </form>
      </mat-tab>
      <!--Recibo-->
    </mat-tab-group>
  </form>
</mat-dialog-content>
<!--Contenido del dialogo o modal-->

<!--Boton de cancelar-->
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>Cancelar</button>
</mat-dialog-actions>
<!--Boton de cancelar-->
