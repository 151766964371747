<div class="container">
  <div class="row py-3">
    <div class="col-md-12">
      <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Tu información básica"
          ><br />
          <div class="container">
            <!--Card de foto y datos de usuario (estudiante)-->
            <div class="row">
              <div class="col-md-8 py-2">
                <mat-card> <h1>Tu información básica</h1> </mat-card><br />
                <!--Datos del estudiante-->
                <form
                  [formGroup]="formUsuario"
                  (ngSubmit)="submitEditarUsuario($event)"
                  class="example-form"
                >
                  <mat-card>
                    <mat-card-header class="py-3">
                      <mat-card-title>Datos de Usuario</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <hr />
                      <mat-form-field class="col-md-12">
                        <mat-label>Nombre Completo:</mat-label>
                        <input
                          matInput
                          formControlName="nombre_usuario"
                          type="text"
                        />
                      </mat-form-field>
                      <mat-form-field class="col-md-12">
                        <mat-label>Correo electrónico:</mat-label>
                        <input
                          matInput
                          formControlName="correo_usuario"
                          type="email"
                        />
                      </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions align="end">
                      <button mat-button type="submit">Guardar cambios</button>
                    </mat-card-actions>
                  </mat-card>
                </form>
              </div>
              <div class="col-md-4 py-2">
                <!--Fotografia del usuario-->
                <mat-card class="foto-usu">
                  <mat-card-header class="py-2">
                    <mat-card-title>{{
                      datosUsuario.nombre_usuario
                    }}</mat-card-title>
                  </mat-card-header>
                  <mat-card-content class="py-2">
                    <img
                      class="img-usuario"
                      mat-card-image
                      *ngIf="!imagenPerfilCambio"
                      [src]="datosUsuario.foto_usuario"
                      fallimg="avatar"
                    />
                    <div *ngIf="imagenPerfilCambio">
                      <img
                        class="img-usuario"
                        mat-card-image
                        [src]="imagenPerfilCambio"
                        alt="{{ datosUsuario.foto_usuario }}"
                      />
                    </div>
                    <mat-progress-bar
                      *ngIf="porcentaje > 0 && porcentaje < 100"
                      color="accent"
                      value="{{ porcentaje }}"
                      max="100"
                      class="col-md-12"
                    >
                      {{ porcentaje }}%</mat-progress-bar
                    >
                  </mat-card-content>
                  <hr />
                  <!--<mat-progress-bar *ngIf="porcentaje > 0 && porcentaje < 100" value="{{porcentaje}}">
                    {{porcentaje}}%</mat-progress-bar>-->
                  <div
                    *ngIf="porcentaje == 100"
                    class="alert alert-success"
                    role="alert"
                  >
                    Archivo cargado: {{ nombreArchivo }}
                  </div>

                  <div class="row">
                    <a
                      *ngIf="!imagenPerfilCambio"
                      (click)="imagen.click()"
                      class="btn btn-success btn-block mx-4"
                    >
                      <span class="fas fa-file-image"></span>
                      Cambiar Foto
                    </a>

                    <button
                      type="button"
                      *ngIf="imagenPerfilCambio"
                      (click)="cambiarFoto()"
                      class="btn btn-primary btn-block mx-4"
                      style="background-color: blueviolet;"
                    >
                      <span class="fas fa-upload"></span>
                      Cargar Foto
                    </button>

                    <a
                      *ngIf="imagenPerfilCambio"
                      (click)="deleteAttachment(0)"
                      class="btn btn-danger btn-block mx-4"
                    >
                      <span class="fas fa-trash"></span>
                      Eliminar Foto
                    </a>
                  </div>
                  <mat-card-actions align="end">
                    <input
                      class="file-input"
                      type="file"
                      name="archivo"
                      #imagen
                      hidden
                      accept="image/*"
                      (change)="uploadFile($event)"
                    />
                  </mat-card-actions>
                </mat-card>
                <!--Fotografia del usuario-->
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab
          *ngIf="datosUsuario.id_rol == 2"
          label="Tu información de Instructor"
          ><br />
          <div class="container">
            <div class="row">
              <div class="col-md-8 py-2">
                <mat-card> <h1>Tu información de Instructor</h1> </mat-card
                ><br />
                <!--Informacion del Docente-->
                <form
                  [formGroup]="formDocente"
                  (ngSubmit)="actualizarPerfilDocente($event)"
                  class="example-form"
                >
                  <div *ngIf="datosUsuario.datos_docente">
                    <mat-card>
                      <mat-card-header class="py-3">
                        <mat-card-title>Datos de Instructor</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <hr />
                        <mat-form-field class="col-md-12">
                          <mat-label>Número de celular:</mat-label>
                          <input
                            matInput
                            formControlName="telefono_docente"
                            type="number"
                          />
                        </mat-form-field>
                        <mat-form-field class="col-md-12">
                          <mat-label>Descripción profesional:</mat-label>
                          <textarea
                            matInput
                            formControlName="descripcion_docente"
                            type="text"
                          ></textarea>
                        </mat-form-field>
                        <mat-form-field class="col-md-12">
                          <mat-label>Experiencia profesional:</mat-label>
                          <textarea
                            matInput
                            formControlName="experiencia_docente"
                            type="text"
                          ></textarea>
                        </mat-form-field>
                      </mat-card-content>
                      <mat-card-actions align="end">
                        <button mat-button type="submit">
                          Guardar cambios
                        </button>
                      </mat-card-actions>
                    </mat-card>
                  </div>
                </form>
                <!--Informacion del Docente-->
              </div>
              <div class="col-md-4 py-2">
                <mat-card>
                  <mat-card-header class="py-2">
                    <mat-card-title>Video de presentación</mat-card-title>
                  </mat-card-header>
                  <mat-card-content class="py-3">
                    <mat-progress-bar
                      *ngIf="porcentajeVideo > 0 && porcentajeVideo < 100"
                      value="{{ porcentajeVideo }}"
                    >
                      {{ porcentajeVideo }}%</mat-progress-bar
                    ><br />
                    <video
                      class="video"
                      *ngIf="!estadoVideo"
                      mat-card-image
                      [src]="datosUsuario.datos_docente.video_presentacion"
                      controls
                      muted
                    ></video>
                    <div *ngIf="estadoVideo">
                      <video
                        mat-card-image
                        class="video"
                        [src]="estadoVideo"
                        controls
                        muted
                      ></video>
                    </div>
                  </mat-card-content>
                  <hr />
                  <mat-card-actions align="end">
                    <input
                      type="file"
                      name="video"
                      #video
                      hidden
                      accept="video/mp4,video/x-m4v,video/*"
                      (change)="uploadVideo($event)"
                    />
                    <button
                      *ngIf="!estadoVideo"
                      mat-raised-button
                      color="primary"
                      matTooltip="edita tu video"
                      (click)="video.click()"
                    >
                      Edita tu video <mat-icon>video_call</mat-icon>
                    </button>
                    <div *ngIf="estadoVideo">
                      <button
                        mat-raised-button
                        color="primary"
                        (click)="cambiarVideo()"
                      >
                        Guardar <mat-icon>check_circle_outline</mat-icon>
                      </button>
                      <button
                        mat-raised-button
                        color="warn"
                        (click)="deleteAttachmentVideo(0)"
                      >
                        Cancelar <mat-icon>error_outline</mat-icon>
                      </button>
                    </div>
                  </mat-card-actions> </mat-card
                ><br />
                <mat-card>
                  <mat-card-header class="py-2">
                    <mat-card-title>Tu hoja de vida CV</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="alert alert-secondary" role="alert">
                      <a
                        mat-stroked-button
                        class="col-md-12"
                        matTooltip="mira tu CV"
                        target="”_blank”"
                        href="{{ datosUsuario.datos_docente.cv_docente }}"
                      >
                        Mira tu Curriculum
                      </a>
                    </div>
                  </mat-card-content>
                  <hr />
                  <span class="file-name">
                    {{ mensajeCv }}
                  </span>
                  <div
                    *ngIf="porcentajeCv == 100"
                    class="alert alert-success"
                    role="alert"
                  >
                    Archivo cargado: {{ nombreCv }}
                  </div>
                  <mat-card-actions align="end">
                    <input
                      type="file"
                      name="fileCv"
                      #cv
                      hidden
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      (change)="uploadCv($event)"
                    />
                    <button
                      *ngIf="!estadoCv"
                      mat-raised-button
                      color="accent"
                      matTooltip="edita tu CV"
                      (click)="cv.click()"
                    >
                      Edita tu CV<mat-icon>edit_note</mat-icon>
                    </button>
                    <div *ngIf="estadoCv">
                      <button
                        mat-raised-button
                        color="primary"
                        (click)="cambiarCv()"
                      >
                        Guardar <mat-icon>check_circle_outline</mat-icon>
                      </button>
                      <button
                        mat-raised-button
                        color="warn"
                        (click)="deleteAttachmentCv(0)"
                      >
                        Cancelar <mat-icon>error_outline</mat-icon>
                      </button>
                    </div>
                  </mat-card-actions>
                </mat-card>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
