import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pago-mone',
  templateUrl: './pago-mone.component.html',
  styleUrls: ['./pago-mone.component.scss']
})
export class PagoMoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
